var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("b-table", {
        ref: "bills-bulk-table",
        class: {
          bordered: true,
          "mb-1": true,
        },
        attrs: {
          "show-empty": "",
          striped: "",
          small: "",
          responsive: "",
          "tbody-tr-class": _vm.rowClass,
          fields: _vm.fields,
          items: _vm.items,
          "empty-text": _vm.getEmptyTableMessage(
            _vm.$tc("ACCOUNTS_PAYABLE.UI.PAGE_XLSX_IMPORT_SAVE.NAME"),
            "female"
          ),
          "current-page": _vm.paging.currentPage,
          "per-page": _vm.paging.pageSize,
        },
        scopedSlots: _vm._u([
          {
            key: "cell(actions)",
            fn: function (ref) {
              var item = ref.item
              return [
                _c("e-grid-actions", {
                  attrs: {
                    "show-update": false,
                    "show-delete": false,
                    buttons: _vm.gridActions,
                  },
                  on: {
                    "show-details": function ($event) {
                      return _vm.onShowDetails(item)
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "cell(storeId)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("e-store-combo", {
                  attrs: {
                    id: "bulk_table-store_id_" + index,
                    name: "storeId_" + index,
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    "show-label": false,
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.storeId,
                    callback: function ($$v) {
                      _vm.$set(item, "storeId", $$v)
                    },
                    expression: "item.storeId",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(statementDate)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-statement_date_" + index,
                    name: "statementDate_" + index,
                    type: "datepicker",
                    placeholder: _vm.$t("dd/MM/aaaa"),
                    validation: "required",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.statementDate,
                    callback: function ($$v) {
                      _vm.$set(item, "statementDate", $$v)
                    },
                    expression: "item.statementDate",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(dueDate)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-due_date_" + index,
                    name: "dueDate_" + index,
                    type: "datepicker",
                    placeholder: _vm.$t("dd/MM/aaaa"),
                    validation: "required",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.dueDate,
                    callback: function ($$v) {
                      _vm.$set(item, "dueDate", $$v)
                    },
                    expression: "item.dueDate",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(description)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-description_" + index,
                    name: "description_" + index,
                    type: "textarea",
                    validation: "required",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                  },
                  on: {
                    blur: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.description,
                    callback: function ($$v) {
                      _vm.$set(item, "description", $$v)
                    },
                    expression: "item.description",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(supplierId)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("e-person-search", {
                  attrs: {
                    id: "bulk_table-supplier_id_" + index,
                    name: "supplierId_" + index,
                    "is-supplier": "",
                    "show-label": false,
                    required: "",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    "default-options": item.defaultSupplierOptions,
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.supplierId,
                    callback: function ($$v) {
                      _vm.$set(item, "supplierId", $$v)
                    },
                    expression: "item.supplierId",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(documentType)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-document_type_" + index,
                    name: "documentType_" + index,
                    type: "vue-select",
                    options: _vm.documentTypeOptions(),
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    validation: "required",
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.documentType,
                    callback: function ($$v) {
                      _vm.$set(item, "documentType", $$v)
                    },
                    expression: "item.documentType",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(document)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-document_" + index,
                    name: "document_" + index,
                    type: "text",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    validation: "required",
                  },
                  on: {
                    blur: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.document,
                    callback: function ($$v) {
                      _vm.$set(item, "document", $$v)
                    },
                    expression: "item.document",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(managementAccountId)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("e-management-account", {
                  attrs: {
                    id: "bulk_table-management_account_id-" + index,
                    name: "managementAccount_" + index,
                    label: "",
                    required: "",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    "show-label": false,
                    "only-active": true,
                    type: "Output",
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.managementAccountId,
                    callback: function ($$v) {
                      _vm.$set(item, "managementAccountId", $$v)
                    },
                    expression: "item.managementAccountId",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(paymentMethodId)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("e-payment-method", {
                  attrs: {
                    id: "bulk_table-payment_method_id-" + index,
                    name: "paymentMethodId_" + index,
                    label: "",
                    required: "",
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                    },
                    "show-label": false,
                  },
                  on: {
                    input: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.paymentMethodId,
                    callback: function ($$v) {
                      _vm.$set(item, "paymentMethodId", $$v)
                    },
                    expression: "item.paymentMethodId",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(value)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-value_" + index,
                    name: "value_" + index,
                    type: "text-number",
                    currency: "R$",
                    precision: 2,
                    "validation-messages": {
                      required: _vm.$t("Obrigatório"),
                      min: _vm.$t("Deve ser maior que 0.01"),
                    },
                    validation: "required|min:0.01",
                  },
                  on: {
                    blur: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.value,
                    callback: function ($$v) {
                      _vm.$set(item, "value", $$v)
                    },
                    expression: "item.value",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(comments)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-comments-" + index,
                    name: "comments_" + index,
                    type: "textarea",
                  },
                  on: {
                    blur: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.comments,
                    callback: function ($$v) {
                      _vm.$set(item, "comments", $$v)
                    },
                    expression: "item.comments",
                  },
                }),
              ]
            },
          },
          {
            key: "cell(installmentComment)",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c("FormulateInput", {
                  attrs: {
                    id: "bulk_table-installment_comment-" + index,
                    name: "installmentComment_" + index,
                    type: "textarea",
                  },
                  on: {
                    blur: function () {
                      return _vm.onBlurField(item)
                    },
                  },
                  model: {
                    value: item.installmentComment,
                    callback: function ($$v) {
                      _vm.$set(item, "installmentComment", $$v)
                    },
                    expression: "item.installmentComment",
                  },
                }),
              ]
            },
          },
          {
            key: "row-details",
            fn: function (ref) {
              var item = ref.item
              var index = ref.index
              return [
                _c(
                  "div",
                  {
                    staticClass: "bg-light p-1 rounded",
                    staticStyle: { "margin-left": "150px" },
                  },
                  [
                    _c("p", { staticClass: "h4" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("Dados importados da planilha")) +
                          " "
                      ),
                    ]),
                    _c(
                      "b-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-store_id-" + index,
                                label: _vm.$t("Loja"),
                                type: "label",
                                errors: item.xlsxErrors["StoreId"]
                                  ? item.xlsxErrors["StoreId"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.storeId,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "storeId", $$v)
                                },
                                expression: "item.xlsxData.storeId",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-statement_date-" + index,
                                label: _vm.$t("Data Emissão"),
                                type: "label",
                                errors: item.xlsxErrors["StatementDate"]
                                  ? item.xlsxErrors["StatementDate"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.statementDate,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "statementDate", $$v)
                                },
                                expression: "item.xlsxData.statementDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-due_date-" + index,
                                label: _vm.$t("Data vencimento"),
                                type: "label",
                                errors: item.xlsxErrors["DueDate"]
                                  ? item.xlsxErrors["DueDate"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.dueDate,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "dueDate", $$v)
                                },
                                expression: "item.xlsxData.dueDate",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("b-col", { attrs: { md: "2" } }, [
                          _c(
                            "div",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id:
                                    "bulk_table_xlsx-supplier_document-" +
                                    index,
                                  label: _vm.$t("Documento do fornecedor"),
                                  type: "label",
                                  errors: item.xlsxErrors["SupplierDocument"]
                                    ? item.xlsxErrors["SupplierDocument"]
                                    : [],
                                },
                                model: {
                                  value: item.xlsxData.supplierDocument,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      item.xlsxData,
                                      "supplierDocument",
                                      $$v
                                    )
                                  },
                                  expression: "item.xlsxData.supplierDocument",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-document_type-" + index,
                                label: _vm.$t("Tipo documento"),
                                type: "label",
                                errors: item.xlsxErrors["DocumentType"]
                                  ? item.xlsxErrors["DocumentType"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.documentType,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "documentType", $$v)
                                },
                                expression: "item.xlsxData.documentType",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-document-" + index,
                                label: _vm.$t("Documento"),
                                type: "label",
                                errors: item.xlsxErrors["Document"]
                                  ? item.xlsxErrors["Document"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.document,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "document", $$v)
                                },
                                expression: "item.xlsxData.document",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-row",
                      { staticClass: "mb-1" },
                      [
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-description-" + index,
                                label: _vm.$t("Descrição"),
                                type: "label",
                                errors: item.xlsxErrors["Description"]
                                  ? item.xlsxErrors["Description"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.description,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "description", $$v)
                                },
                                expression: "item.xlsxData.description",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id:
                                  "bulk_table_xlsx-management_account-" + index,
                                label: _vm.$t("Nome da conta gerencial"),
                                type: "label",
                                errors: item.xlsxErrors["ManagementAccountName"]
                                  ? item.xlsxErrors["ManagementAccountName"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.managementAccountName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.xlsxData,
                                    "managementAccountName",
                                    $$v
                                  )
                                },
                                expression:
                                  "item.xlsxData.managementAccountName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-payment_method-" + index,
                                label: _vm.$t("Forma de pagamento"),
                                type: "label",
                                errors: item.xlsxErrors["PaymentMethodName"]
                                  ? item.xlsxErrors["PaymentMethodName"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.paymentMethodName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.xlsxData,
                                    "paymentMethodName",
                                    $$v
                                  )
                                },
                                expression: "item.xlsxData.paymentMethodName",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-value-" + index,
                                label: _vm.$t("Valor"),
                                type: "label",
                                errors: item.xlsxErrors["Value"]
                                  ? item.xlsxErrors["Value"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.value,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "value", $$v)
                                },
                                expression: "item.xlsxData.value",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id: "bulk_table_xlsx-comments-" + index,
                                label: _vm.$t("Observação"),
                                type: "label",
                                errors: item.xlsxErrors["Comments"]
                                  ? item.xlsxErrors["Comments"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.comments,
                                callback: function ($$v) {
                                  _vm.$set(item.xlsxData, "comments", $$v)
                                },
                                expression: "item.xlsxData.comments",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "b-col",
                          { attrs: { md: "2" } },
                          [
                            _c("FormulateInput", {
                              attrs: {
                                id:
                                  "bulk_table_xlsx-installment_comment-" +
                                  index,
                                label: _vm.$t("Observação da parcela"),
                                type: "label",
                                errors: item.xlsxErrors["InstallmentComment"]
                                  ? item.xlsxErrors["InstallmentComment"]
                                  : [],
                              },
                              model: {
                                value: item.xlsxData.installmentComment,
                                callback: function ($$v) {
                                  _vm.$set(
                                    item.xlsxData,
                                    "installmentComment",
                                    $$v
                                  )
                                },
                                expression: "item.xlsxData.installmentComment",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "custom-foot",
            fn: function () {
              return [
                _c("tr", [
                  _c(
                    "th",
                    { staticClass: "text-right", attrs: { colspan: "10" } },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("Correção pendente") +
                              ": " +
                              _vm.summary.totalErrors
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c("th", { staticClass: "text-right" }, [_vm._v(" Total ")]),
                  _c("th", { staticClass: "text-center" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("currency")(_vm.summary.totalItems)) +
                        " "
                    ),
                  ]),
                  _c("th", { attrs: { colspan: "2" } }),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }